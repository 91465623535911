document.addEventListener('alpine:init', () => {
  Alpine.data('cart_coupon', (data) => {
    return {
        ...data,
        updateDiscountCode: function(newValue, $refs) {
            if  (this.state === 'applied' && newValue !== this.discount_code) {
                this.applied_discount_code = this.discount_code
                this.state = 'replaced'
                $refs.form.action = this.applied_cart_path
            }
            if (this.state === 'replaced' && newValue === this.applied_discount_code) {
                this.state = 'applied'
                $refs.form.action = this.removed_cart_path
            }
            this.discount_code = newValue
        },
        buttonLabel: function() {
          switch (this.state) {
              case 'submitting':
                  return ''
              case 'unused':
              case 'error':
                  return 'Apply Code'
              case 'applied':
                  return 'Remove'
              case 'replaced':
                  return 'Replace Code'
          }
        },
        submit: function (url) {
            this.state = url ? 'unused' : 'submitting'
            let $form = $(this.$refs.form)
            $.ajax({
                type: "POST",
                url: url || $form.prop('action'),
                data: $form.serialize(),
                success: (_) => {
                    window.location = this.cart_path
                },
                error: (_) => {
                    this.error_message = '<div class="text-error-600">Could not apply coupon code</div>'
                    this.state = 'error'
                }
            });
        }
    };
  });

  Alpine.data('cart_applied_coupon', (data) => {
    return {
      ...data,
      updateCouponForm: function(distributor_coupon_hash){
        if (distributor_coupon_hash.distributor_id == this.distributor_id ) {
          this.coupon_code_requirements_message = distributor_coupon_hash.coupon_code_requirements_message
        }
      }
    }
  });
})