/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Alpine from 'alpinejs'
import Clipboard from "@ryangjchandler/alpine-clipboard"
import mask from '@alpinejs/mask'
import intersect from '@alpinejs/intersect'

// import src directory files
function importAll(r) {
  r.keys().forEach(r);
}

importAll(require.context('../src', true, /\.js|\.js\.erb/));

import Swal from 'sweetalert2'
window.Swal = Swal;

Alpine.plugin(Clipboard)
Alpine.plugin(mask)
Alpine.plugin(intersect)

window.Alpine = Alpine
Alpine.start()

require('stylesheets/tailwind-imports.css')

import tailwindConfig from '/tailwind.config.js'
window.tailwindColors = tailwindConfig.theme.colors

importAll(require.context('../stylesheets', true, /\.css|\.scss/))
require("stylesheets/application.scss")

require('/vendor/assets/javascripts/addtohomescreen.js')
require('/vendor/assets/stylesheets/addtohomescreen.css')