window.initializePopper = function initializePopper(popperElement, secondOptions = {}){
  let popperTooltip = findSibling(popperElement, '.popper-tooltip')
  if (popperTooltip === undefined) { return }

  let defaultOptions = {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      }
    ],
    placement: 'right-start'
  }
  let options = mergeOptions(defaultOptions, secondOptions)
  let popperInstance = Popper.createPopper(popperElement, popperTooltip, options);

  function show(event) {
    popperTooltip.setAttribute('data-show', '');

    //Enable the event listeners
    let eventListenerObj = { modifiers: [ { name: 'eventListeners', enabled: true } ] }
    options = mergeOptions(options, eventListenerObj)
    popperInstance.setOptions(options);

    // We need to tell Popper to update the tooltip position
    // after we show the tooltip, otherwise it will be incorrect
    popperInstance.update();

    // Touch events are very finicky especially right next to other events
    // Opt to just autohide after a few seconds
    if(event.pointerType == 'touch'){
      setTimeout(function(){hide();}, 2000);
    }
  }

  function hideForPopperElement(event){    
    if(event.pointerType == 'touch') return;
    // Hover over tooltip still shows tooltip
    if(event.type == 'pointerleave'){
      setTimeout(function(){
        if(!popperElement.matches(':hover') && !popperTooltip.matches(':hover')){
          hide();
        }        
      },250);
    }
  }
  function hideForPopperTooltip(event){
    hide();
  }

  function hide() {
    popperTooltip.removeAttribute('data-show');

    // Disable the event listeners
    let eventListenerObj = { modifiers: [ { name: 'eventListeners', enabled: true } ] }
    options = mergeOptions(options, eventListenerObj)
    popperInstance.setOptions(options);
  }

  const showEvents = ['pointerenter', 'focus'];
  const hideEvents = ['pointerleave', 'blur'];

  showEvents.forEach(event => {
    popperElement.addEventListener(event, show);
  });

  hideEvents.forEach(event => {
    popperElement.addEventListener(event, hideForPopperElement);
    popperTooltip.addEventListener(event, hideForPopperTooltip);
  });

  // Properties will be overwritten by second options obj 
  // Modifiers will be deep copied 
  function mergeOptions(optionsObj, secondOptionsObj){
    let modifiers = secondOptionsObj.modifiers ?? optionsObj.modifiers
    if (!!optionsObj.modifiers && !!secondOptionsObj.modifiers){
      let optionsObjModifiers = optionsObj.modifiers
      let secondOptionModifierNames = secondOptionsObj.modifiers.map(value => value.name);
      let filteredModifiers = optionsObjModifiers.filter(value => !secondOptionModifierNames.includes(value.name))
      modifiers = secondOptionsObj.modifiers
      filteredModifiers.forEach(value => modifiers.push(value))
    }
    return {
      ...optionsObj,
      ...secondOptionsObj,
      ...{ modifiers: modifiers }
    }
  }

  function findSibling(el, querySelector){
    let sibling = el.nextElementSibling;
    if(sibling === undefined){ return; }

    while (sibling){
      if (sibling.matches(querySelector)) { return sibling }
      sibling = sibling.nextElementSibling;
    }
  }
}
