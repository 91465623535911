import {domReady} from "./utils"
window.BREAKPOINTS = {
  'xs': 375,
  'sm': 576,
  'md': 768,
  'lg': 1024,
  'xl': 1280
}
window.notifyErrorTw = function notifyErrorTw(message, title='') {
  Alpine.store('notifications').add('error', message, title)
}
window.notifyNoticeTw = function notifyNoticeTw(message, title='') {
  Alpine.store('notifications').add('info', message, title)
}

async function initializeConfirmBoxes() {
  await domReady();
  let confirmElements = Array.from(document.getElementsByClassName('generic-confirm'))
  confirmElements.forEach((element) => element.addEventListener('click', function(){
    handleGenericAction(element)
  }))
  let deleteConfirmElements = Array.from(document.getElementsByClassName('delete-confirm'))
  deleteConfirmElements.forEach((element) => element.addEventListener('click', function(){
    handleDeleteAction(element)
  }))
}

initializeConfirmBoxes();

window.confirmBox = function confirmBox(message, callback, parameterOptions){
  parameterOptions = parameterOptions || {};

  //Sanitize Script Tags
  let messageDiv = document.createElement('div');
  messageDiv.innerHTML = message;
  ['script','iframe','embed','html','body'].forEach((tagName) => function(){
    let elements = Array.from(messageDiv.getElementsByTagName(tagName));
    elements.forEach((element) => function(){
      element.parentNode.removeChild(element);
    })
  })

  let defaultOptions = {
    html: messageDiv.innerHTML,
    reverseButtons: true,
    showCancelButton: true,
    buttonsStyling: false,
    customClass: {
      confirmButton: 'button bg-white neutral-text-500 hover:bg-gray-50 mx-2',
      cancelButton: 'button bg-white neutral-text-500 hover:bg-gray-50 mx-2',
      denyButton: 'button bg-white text-error-600 hover:bg-gray-50 mx-2'
    }
  }
  let options = {
    ...defaultOptions,
    ...parameterOptions
  }
  Swal.fire(options).then(function(result){
    if(result.isConfirmed == true ) {
      if(!!callback){
        callback();
      }
    }
  });
}

function handleGenericAction(element) {
  let url = element.dataset.url || element.dataset.ajaxPath;
  let message = element.dataset.confirmMessage
  if (message == undefined || message == '') {
    message = 'Are you sure you want to do this?'
  }
  let separateWindow = element.dataset.separateWindow || false
  confirmBox(message, function(){
    if(separateWindow) {
      window.open(url);
    } else {
      window.location = url;
    }
  })
}

function handleDeleteAction(element) {
  let deleteUrl = element.dataset.deleteUrl;
  let postDeleteRedirect = element.dataset.postDeleteRedirect;
  let message = element.dataset.confirmMessage;
  if (message == undefined || message == '') {
      message = 'Are you sure you want to do this?';
  }
  confirmBox(
      message,
      function () {
          $.ajax({
              method: 'delete',
              data: {authenticity_token: SiCsrfToken()},
              url: deleteUrl,
              dataType: 'json',
              success: function () {
                  window.location = postDeleteRedirect;
              },
              error: function (response) {
                  let errorMessage = 'Something went wrong. No action taken.';
                  if(!!response.errors){
                      errorMessage = response.errors;
                  }
                  notifyErrorTw(errorMessage);
              }
          });
      }
  )
}

window.swal = (options) => Swal.fire(options)
window.SiCsrfToken = () => document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
window.jsonRequestHeaders = {
  'Content-Type': 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
  'X-CSRF-Token': SiCsrfToken(),
  'Accept': 'application/json'
}

window.serializeForm = (formData) => {
  let dataObj = {};
  for (var key of formData.keys()) {
    let multiFormArgument = key.match(/\[\w*\]/)
    if (!!multiFormArgument){
      multiFormArgument = multiFormArgument[0]
      let splitArguments = key.split(multiFormArgument)
      if (splitArguments.every((arg) => { return arg.length > 0 })){
        let hashKey = multiFormArgument.match(/\w+/)
        dataObj[hashKey] = dataObj[hashKey] || {}
        dataObj[hashKey][splitArguments.pop()] = formData.get(key);
      } else {
        dataObj[key] = dataObj[key] || []
        dataObj[key].push(formData.get(key))
      }
    } else {
      dataObj[key] = formData.get(key);
    }
  }
  return dataObj;
}

window.clearParamsFromAddressBar = () =>  window.history.pushState({}, document.title, window.location.pathname);

window.removeAllChildNodes = (parent) => {
  while (parent.firstChild) {
    parent.removeChild(parent.firstChild);
  }
}

window.fireDeactivateNotificationModal = async (event, deactivateUrl) => {
  const result = await swal({
    title: 'Deactivate Notification',
    text: "Are you sure you want to deactivate notification? Notification will no longer be displayed for any users, This action cannot be undone.",
    icon: 'warning',
    showCancelButton: true,
    reverseButtons: true,
    confirmButtonColor: '#EE6161',
    cancelButtonColor: '#FFFFFF',
    cancelButtonClass: 'button-si button-color-white button-size-base mx-2',
    confirmButtonClass: 'button-si button-color-red button-size-base mx-2',
    confirmButtonText: 'Deactivate Notification',
    focusConfirm: false,
    buttonsStyling: false,
  })

  if (result.isConfirmed) {
    const putParams = {
      method: 'PUT',
      headers: {'Content-Type': 'application/json', 'X-CSRF-Token': SiCsrfToken()},
      body: JSON.stringify({notification: {result}})
    };

    const response = await fetch(deactivateUrl, putParams)
    if (response.ok) {
      const deactivatedEvent = new CustomEvent('notificationDeactivated', {bubbles: true})
      event.target.dispatchEvent(deactivatedEvent)
      notifyNoticeTw('Notification has been deactivated and is no longer live.', 'Successfully deactivated notification', 'success')
    } else {
      try {
        const {message: errorMessage} = await response.json()
        console.error(errorMessage)
      } catch (error) {
        console.error(error)
      } finally {
        notifyErrorTw('Unable to deactivate notification', 'Error')
      }
    }
  }
}

window.setHtmlWithScripts = (targetElement, htmlString) => {
  // Clear out the innerHTML
  targetElement.innerHTML = '';

  // Create a temporary DOM Element to scan for Script Tags
  let divElement = document.createElement('div')
  divElement.innerHTML = htmlString

  // Set two script arrays since removing the child from the DOM element will remove it from the array as well
  let scriptElements = divElement.getElementsByTagName('script')
  let scriptElementsToKeep = []

  // Loop through script elements and remove from temporary DOM Element and store later
  while(scriptElements.length > 0) {
    scriptElementsToKeep.push(scriptElements[0].parentNode.removeChild(scriptElements[0]));
  }

  // ReAdd the scripts to the target element
  // We need to create a new script tag for them to run.
  for( let i = 0; i < scriptElementsToKeep.length; i++) {
    let scriptNewElement = document.createElement('script')
    // If there are alpine;init, run them instead of inserting
    scriptNewElement.innerHTML = scriptElementsToKeep[i].innerHTML
    scriptNewElement.innerHTML = scriptNewElement.innerHTML.replace('alpine:init', 'alpine:custom:load')
    targetElement.appendChild(scriptNewElement)
  }

  // While this works, it's important to note that it may run again for components that already have this mapped so we should move this back to the original event.
  document.dispatchEvent(new Event('alpine:custom:load'));
  targetElement.innerHTML.replace('alpine:custom:load', 'alpine:init')

  // Set the TargetElement InnerHTML before the scripts
  targetElement.innerHTML = divElement.innerHTML + targetElement.innerHTML
}