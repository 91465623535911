// import {domReady} from "./utils"

const AlpineMq = (function () {
    let matchMedia = window.matchMedia
    let queries = {
        0: {min: matchMedia('(min-width: 0)')},
        xs: {
            min: matchMedia('(min-width: 375px)'),
            max: matchMedia('(max-width: 374px)'),
        },
        sm: {
            min: matchMedia('(min-width: 640px)'),
            max: matchMedia('(max-width: 639px)'),
        },
        md: {
            min: matchMedia('(min-width: 768px)'),
            max: matchMedia('(max-width: 767px)'),
        },
        lg: {
            min: matchMedia('(min-width: 1024px)'),
            max: matchMedia('(max-width: 1023px)'),
        },
        xl: {
            min: matchMedia('(min-width: 1280px)'),
            max: matchMedia('(max-width: 1279px)'),
        },
    }

    let inputs = {
        touch: matchMedia('(hover: none) and (pointer: coarse)'),
        mouse: matchMedia('(hover: hover) and (pointer: fine)'),
        stylus: matchMedia('(hover: none) and (pointer: fine)'),
        controller: matchMedia('(hover: hover) and (pointer: coarse)'),
    }

    let proxy = new Proxy({}, {
        get(target, name, _) {
            let intents = {
                '-': () => {
                    let [from, to] = name.split('-')
                    return queries[from].min.matches && queries[to].max.matches
                },
                'input': () => inputs[name.replace('input', '').toLowerCase()].matches
            }
            let intent = Object.keys(intents).find((i) => name.includes(i))
            return intent ? intents[intent]() : queries[name].min.matches
        }
    })

    let subscribers = []
    let resizeObserver

    return {
        async start() {
           //Scripts are defered now so page is already loaded
           //await domReady()
          if (typeof ResizeObserver === 'function') {
              resizeObserver = new ResizeObserver(_ => {
                  subscribers.forEach($el => !!$el.__x && $el.__x.updateElements($el)) // Add this to trigger mutations on update
              })
              resizeObserver.observe(document.body)
          }
          Alpine.magic('mq', function ($el) {
              if (!subscribers.includes($el)) subscribers.push($el)
              return proxy
          })
        }
    }
})()

document.addEventListener('alpine:init', () => {
  // Will be executed before initializing Alpine.
  AlpineMq.start()
})

document.addEventListener('alpine:initialized', () => {
  // Will be executed after initializing Alpine.
})