const plugin = require('tailwindcss/plugin')
const flattenColorPalette = require('tailwindcss/lib/util/flattenColorPalette').default

// TODO: drop all the now default styles and move to extend after updating to v3
module.exports = {
  content: [
    './app/**/*.html.erb',
    './app/views/**/*.html.erb',
    './app/helpers/**/*.rb',
    './app/javascript/**/*.js',
    './app/javascript/**/*.vue',
    './app/javascript/**/*.jsx',
    './app/components/**/*.rb',
    './app/components/**/*.html.erb',
    './test/components/**/*.html.erb',
    './test/components/previews/**/*.html.erb'
  ],
  prefix: '',
  important: false,
  separator: ':',
  theme: {
    extend: {
      inset: {
        '0': '0',
        '0.5': '0.5rem',
        auto: 'auto',
        '1/2': '50%',
        '1': '1rem',
        'menu-height': '4rem'
      },
      flex: {
        '3': '3 3 0%'
      },
      gridAutoRows: {
        '226px': '226px'
      },
      spacing: {
        '0.75': '0.188rem',
        '4.5': '1.125rem',
        '77': '19.25rem',
        '114': '28.5rem',
        '50px': '50px',
        '400px': '400px'
      },
      backgroundSize: {
        '5': '1.25rem',
        '7': '1.75rem'
      },
      backgroundPosition: {
        'left-12': '12px center'
      },
      zIndex: {
      '60': 60,
      '70': 70,
      '80': 80
    }
    },
    screens: {
      xs: '375px',
      '2xs': '450px',
      sm: '640px',
      md: '768px',
      '2md': '980px',
      lg: '1024px',
      xl: '1280px',

    },
    colors: {
      transparent: 'transparent',
      current: 'currentColor',
      black: '#030303',
      white: '#fff',
      gray: {
        '25': '#FCFCFD',
        '50': '#F9FAFB',
        '100': '#F2F4F7',
        '200': '#EAECF0',
        '300': '#D0D5DD',
        '400': '#98A2B3',
        '500': '#667085',
        '600': '#475467',
        '700': '#344054',
        '800': '#1D2939',
        '900': '#101828'
      },
      primary: {
        '25': '#F5F8FF',
        '50': '#EFF4FF',
        '100': '#B9CCFC',
        '200': '#95B2FA',
        '300': '#7299F9',
        '400': '#4F80F7',
        '500': '#2C66F6',
        '600': '#2555CD',
        '700': '#1D44A4',
        '800': '#16337B',
        '900': '#0F2252'
      },
      secondary: {
        "25": "#FCFAFF",
        "50": "#F9F5FF",
        "100": "#F4EBFF",
        "200": "#E9D7FE",
        "300": "#D6BBFB",
        "400": "#B692F6",
        "500": "#9E77ED",
        "600": "#7F56D9",
        "700": "#6941C6",
        "800": "#53389E",
        "900": "#42307D"
      },
      error: {
        '25': '#FFFBFA',
        '50': '#FEF3F2',
        '100': '#FEE4E2',
        '200': '#FECDCA',
        '300': '#FDA29B',
        '400': '#F97066',
        '500': '#F04438',
        '600': '#D92D20',
        '700': '#B42318',
        '800': '#912018',
        '900': '#7A271A'
      },
      warning: {
        '25': '#FFFCF5',
        '50': '#FFFAEB',
        '100': '#FEF0C7',
        '200': '#FEDF89',
        '300': '#FEC84B',
        '400': '#FDB022',
        '500': '#F79009',
        '600': '#DC6803',
        '700': '#B54708',
        '800': '#93370D',
        '900': '#7A2E0E'
      },
      success: {
        '25': '#F6FEF9',
        '50': '#ECFDF3',
        '100': '#D1FADF',
        '200': '#A6F4C5',
        '300': '#6CE9A6',
        '400': '#32D583',
        '500': '#12B76A',
        '600': '#039855',
        '700': '#027A48',
        '800': '#05603A',
        '900': '#054F31'
      },
      purple: {
        '25': '#FBFAFF',
        '50': '#F5F3FF',
        '100': '#F4EBFF',
        '200': '#E9D7FE',
        '300': '#C38BFC',
        '400': '#912BFA',
        '500': '#7B00F9',
        '600': '#6700CF',
        '700': '#5200A6',
        '800': '#3E007D',
        '900': '#290053'
      },
      pink: {
        '25': '#FEF6FB',
        '50': '#FDF2FA',
        '100': '#FCE7F6',
        '200': '#FCCEEE',
        '300': '#FAA7E0',
        '400': '#F670C7',
        '500': '#EE46BC',
        '600': '#DD2590',
        '700': '#C11574',
        '800': '#9E165F',
        '900': '#851651'
      },
      social: {
        "facebook": "#3a5695",
        "facebook-hover": "#233459",
        "twitter": "#55acee",
        "twitter-hover": "#33678F",
        "google": "#e05139",
        "google-hover": "#863122"
      },
      accent: {
        "copper": "#f4bcb8",
        "copper-75": "#c6938a",
        "copper-25": "#ecdbd8",
      }
    },
    borderRadius: {
      DEFAULT: '0.25rem',
      xl: '0.675rem',
      full: '9999px',

      // TODO: determine why @applys break with these default values removed (even if safelisted)
      sm: '0.125rem',
      md: '0.375rem',
      lg: '0.5rem',
      none: '0px'
    },
    borderWidth: {
      DEFAULT: '1px',
      '0': '0',
      '0.5': '0.5px',
      '2': '2px',
      '4': '4px',
      '6': '6px',
      '8': '8px',
      '10': '10px',
      '12': '12px'
    },
    // TODO: audit
    boxShadow: {
      xs: '0 0 0 1px rgba(0, 0, 0, 0.05)',
      xsGray: '0 0 0 1px rgba(16, 24, 40, 0.05)',
      sm: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
      DEFAULT: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
      md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
      lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
      xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
      '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
      inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
      outline: '0 0 0 3px rgba(66, 153, 225, 0.5)',
      none: 'none',
      drawer: '0px 8px 8px -4px #10182808, 0px 20px 24px -4px #10182814',

      // NEW:
      focusedPrimary: '0px 0px 0px 4px #B9CCFC',
      focusedSecondaryGray: '0px 0px 0px 4px #F2F4F7',
      focusedSecondaryPurple: '0px 0px 0px 4px #F4EBFF',
      focusedDestructive: '0px 0px 0px 4px #FEE4E2',
      focusedHeavyGray: '0px 0px 0px 4px #00000080, 0px 1px 2px 0px #1018280D',
      focusedInput: '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(185, 204, 252, 0.5)',
      notification: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)'
    },
    container: {
      center: true,
    },
    fontFamily: {
      'sans': ['Inter', 'Helvetica', 'Arial', 'sans-serif']
    },
    fontSize: {
      'header-lg': ['3rem', '3.75rem'],
      'header-md': ['2.25em', '2.75rem'],
      'header-sm': ['1.875rem', '2.375rem'],
      'header-xs': ['1.5rem', '2rem'],
      'xl': ['1.25rem', '1.875rem'],
      'lg': ['1.125rem', '1.75rem'],
      'md': ['1rem', '1.5rem'],
      'sm': ['0.875rem', '1.25rem'],
      'xs': ['0.75rem', '1.125rem']
    },
    height: theme => ({
      auto: 'auto',
      ...theme('spacing'),
      '1/2': '50%',
      '3/4': '75%',
      full: '100%',
      screen: '100vh',
      'mobile-nav': 'calc(100vh - 4rem)',
      'max-content': 'max-content',
      'fit-content': 'fit-content',

      // NEW
      '18': '4.5rem',
      '35': '8.875rem',
      '90': '5.625rem',
      '64px': '64px',
      '270px': '270px',
      '465px': '465px'
    }),
    letterSpacing: {
      tighter: '-0.05em',
      tight: '-0.025em',
      normal: '0',
      wide: '0.025em',
      wider: '0.05em',
      widest: '0.1em',
    },
    lineHeight: {
      none: '1',
      tighter: '1.1',
      tight: '1.25',
      snug: '1.375',
      normal: '1.5',
      relaxed: '1.625',
      loose: '2',
      '3': '.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem',
      '14': '3.5rem',
    },
    listStyleType: {
      none: 'none',
      disc: 'disc',
      decimal: 'decimal',
    },
    maxHeight: {
      0: 0,
      '4': '4rem',
      '56': '14rem',
      '138px': '138px',
      '200px': '200px',
      '300px': '300px',
      '3/4': '75%',
      full: '100%',
      'half-screen': '50vh',
      '3/5-screen': '60vh',
      '2/3-screen': '66vh',
      '3/4-screen': '75vh',
      screen: '100vh',
      hero: '500px',
      autocomplete: 'calc(100vh - 10rem)',
      slideOut: 'calc(100vh - 4rem)'
    },
    maxWidth: (theme, {breakpoints}) => ({
      none: 'none',
      xs: '20rem',
      sm: '24rem',
      md: '28rem',
      lg: '32rem',
      xl: '36rem',
      '2xl': '42rem',
      '3xl': '48rem',
      '4xl': '56rem',
      '5xl': '64rem',
      '6xl': '72rem',
      '1/4': '25%',
      '1/2': '50%',
      '2/3': '66.666667%',
      'half-screen': '50vw',
      '3/4-screen': '75vw',
      full: '100%',
      '150px': '150px',
      '300px': '300px',
      '350px': '350px',
      '360px': '360px',
      '390px': '390px',
      '400px': '400px',
      '980px': '980px',
      ...breakpoints(theme('screens')),
    }),
    minHeight: {
      '0': '0',
      full: '100%',
      '1/3-screen': '33.333333vh',
      '1/2-screen': '50vh',
      screen: '100vh',
      '30px': '30px',
      '35px': '35px',
      '50px': '50px',
      '60px': '60px',
      '65px': '65px'
    },
    minWidth: {
      '0': '0',
      '1/6': '16.67%',
      '1/5': '20%',
      '1/4': '25%',
      '1/3': '33.333333%',
      '5/12': '41.666667%',
      '1/2': '50%',
      '3/4': '75%',
      full: '100%',
      '150px': '150px',
      '300px': '300px'
    },
    width: theme => ({
      auto: 'auto',
      ...theme('spacing'),
      '1/2': '50%',
      '1/3': '33.333333%',
      '2/3': '66.666667%',
      '1/4': '25%',
      '2/4': '50%',
      '3/4': '75%',
      '1/5': '20%',
      '2/5': '40%',
      '3/5': '60%',
      '4/5': '80%',
      '1/6': '16.666667%',
      '2/6': '33.333333%',
      '3/6': '50%',
      '4/6': '66.666667%',
      '5/6': '83.333333%',
      '1/12': '8.333333%',
      '2/12': '16.666667%',
      '3/12': '25%',
      '4/12': '33.333333%',
      '5/12': '41.666667%',
      '6/12': '50%',
      '7/12': '58.333333%',
      '8/12': '66.666667%',
      '9/12': '75%',
      '10/12': '83.333333%',
      '11/12': '91.666667%',
      full: '100%',
      screen: '100vw',
      '90px': '90px',
      '150px': '150px',
      '300px': '300px',
      '358px': '358px',
      '390px': '390px',
      '394px': '394px',
      '400px': '400px',
      '544px': '544px',
      '792px': '792px',
      'max-content': 'max-content',
      'fit-content': 'fit-content'
    }),
    transformOrigin: {
      center: 'center',
      top: 'top',
      'top-right': 'top right',
      right: 'right',
      'bottom-right': 'bottom right',
      bottom: 'bottom',
      'bottom-left': 'bottom left',
      left: 'left',
      'top-left': 'top left',
    },
    scale: {
      '0': '0',
      '50': '.5',
      '75': '.75',
      '90': '.9',
      '95': '.95',
      '100': '1',
      '105': '1.05',
      '110': '1.1',
      '125': '1.25',
      '150': '1.5',
      '200': '2.0',
    },
    rotate: {
      '-180': '-180deg',
      '-90': '-90deg',
      '-45': '-45deg',
      '0': '0',
      '45': '45deg',
      '90': '90deg',
      '180': '180deg',
      '540': '540deg'
    },
    translate: (theme, {negative}) => ({
      ...theme('spacing'),
      ...negative(theme('spacing')),
      '-full': '-100%',
      '-1/2': '-50%',
      '1/2': '50%',
      full: '100%',
    }),
    transitionProperty: {
      none: 'none',
      all: 'all',
      DEFAULT: 'background-color, border-color, color, fill, stroke, opacity, box-shadow, transform',
      colors: 'background-color, border-color, color, fill, stroke',
      opacity: 'opacity',
      shadow: 'box-shadow',
      transform: 'transform',
      width: 'width',
      height: 'height'
    },
    animation: {
      none: 'none',
      spin: 'spin 1s linear infinite',
      ping: 'ping 1s cubic-bezier(0, 0, 0.2, 1) infinite',
      pulse: 'pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite',
      bounce: 'bounce 1s infinite',
    },
    keyframes: {
      spin: {
        from: {transform: 'rotate(0deg)'},
        to: {transform: 'rotate(360deg)'},
      },
      ping: {
        '0%': {transform: 'scale(1)', opacity: '1'},
        '75%, 100%': {transform: 'scale(2)', opacity: '0'},
      },
      pulse: {
        '0%, 100%': {opacity: '1'},
        '50%': {opacity: '.5'},
      },
      bounce: {
        '0%, 100%': {
          transform: 'translateY(-25%)',
          animationTimingFunction: 'cubic-bezier(0.8,0,1,1)',
        },
        '50%': {
          transform: 'translateY(0)',
          animationTimingFunction: 'cubic-bezier(0,0,0.2,1)',
        },
      },
    }
  },
  corePlugins: {}, // in case we ever want to disable plugins which are enabled by default
  plugins: [
    require('@tailwindcss/forms')({
      strategy: 'class',
    }),
    ({addUtilities, e, theme, variants}) => {
      const colors = flattenColorPalette(theme('borderColor'))
      delete colors['DEFAULT']
      const colorMap = Object.keys(colors)
        .map(color => ({
          [`.border-t-${color}`]: {borderTopColor: colors[color]},
          [`.border-r-${color}`]: {borderRightColor: colors[color]},
          [`.border-b-${color}`]: {borderBottomColor: colors[color]},
          [`.border-l-${color}`]: {borderLeftColor: colors[color]}
        }))
      const utilities = Object.assign({}, ...colorMap)

      addUtilities(utilities, variants('borderColor'))
    },
  ],
}
