export const domReady = () => {
    return new Promise(resolve => {
        if (document.readyState === 'loading') {
            document.addEventListener('DOMContentLoaded', resolve)
        } else {
            resolve()
        }
    })
}

window.centsToCurrency = (value, trim) => {
    const intValue = value / 100;

    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: trim ? 0 : 2,
        maximumFractionDigits: trim ? 0 : 2
    }).format(intValue);
}