document.addEventListener('alpine:init', () => {
  Alpine.store('fetchers', {
    requestHeaders: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': SiCsrfToken(),
      'Accept': '*/*'
    },
    get(url, requestHeaders = this.requestHeaders) {
      return fetch(url, {
        method: 'GET',
        headers: requestHeaders,
      });
    },
    post(url, data = {}, requestHeaders = this.requestHeaders) {
      return fetch(url, {
        method: 'POST',
        headers: requestHeaders,
        body: JSON.stringify(data)
      });
    },
    put(url, data = {}, requestHeaders = this.requestHeaders) {
      return fetch(url, {
        method: 'PUT',
        headers: requestHeaders,
        body: JSON.stringify(data)
      });
    },
    delete(url, data = {}, requestHeaders = this.requestHeaders) {
      return fetch(url, {
        method: 'DELETE',
        headers: requestHeaders,
        body: JSON.stringify(data)
      });
    },
    postJSON(url, data = {}) {
      return this.post(url, data, jsonRequestHeaders)
    },
    putJSON(url, data = {}) {
      return this.put(url, data, jsonRequestHeaders)
    },
    patchJSON(url, data = {}) {
      return fetch(url, {
        method: 'PATCH',
        headers: jsonRequestHeaders,
        body: JSON.stringify(data)
      })
    }
  })
})