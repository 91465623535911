import EmblaCarousel from 'embla-carousel';

document.addEventListener('alpine:init', () => {
    Alpine.data('carousel', (options = { loop: false }) => {
        return {
            embla: null,
            canScrollNext: false,
            canScrollPrev: false,
            scrollSnapList: [],
            selectedScrollSnap: 0,
            canLoop: false,
            init() {
                this.options = options
                this.embla = EmblaCarousel(this.$refs.viewport, this.options)        
                this.embla.on('select', this.updateNav())
                this.embla.on('init', this.updateNav())
                this.embla.on('resize', () => {
                    this.embla.reInit(this.options)
                    this.updateNav()()
                })
            },
            updateNav() {
                return () => {
                    if (this.embla) {
                        this.canScrollNext = this.embla.canScrollNext()
                        this.canScrollPrev = this.embla.canScrollPrev()
                        this.scrollSnapList = this.embla.scrollSnapList()
                        this.selectedScrollSnap = this.embla.selectedScrollSnap()
                        this.canLoop = this.embla.internalEngine().slideLooper.canLoop()
                        if (this.options.loop && !this.canLoop) {
                            this.embla.reInit({ ...this.options, loop: false, draggable: false })
                        }
                    }
                }
            },
            reInit() {
                this.embla.reInit(this.options)
                this.updateNav()()
            },
            nextButton: {
                [':disabled']() {                
                    return !this.canScrollNext
                },
                ['@click']() {
                    this.embla.scrollNext()
                }
            },
            prevButton: {
                [':disabled']() {
                    return !this.canScrollPrev
                },
                ['@click']() {
                    this.embla.scrollPrev()
                }
            }
        }
    });
});