const AlpineFetchMagicMethod = {
    start() {
        Alpine.magic('fetch', function () {
            return (...parameters) => {
                if (typeof parameters[0] === 'string' && parameters[0].length) {
                    return $.get(parameters[0]).then(response => Object.prototype.hasOwnProperty.call(response, 'data') ? response.data : response)
                }

                if (typeof parameters[0] === 'object') {
                    return $.ajax(parameters[0])
                }
                return parameters[0]
            }
        })
    },
}

document.addEventListener('alpine:init', () => {
  // Will be executed before initializing Alpine.
  AlpineFetchMagicMethod.start()
})

document.addEventListener('alpine:initialized', () => {
  // Will be executed after initializing Alpine.
})